import clientSay1 from "images/client1.jpg";
import clientSay4 from "images/client2.jpg";
import clientSay5 from "images/client3.jpg";

export const globalJson = {
  prod_company_id: 112,
  dev_company_id: 112,

  // apiUtil auth header key
  authToken: "Bearer 168|3oLFn8OniSxk1UBTO3eQeGXMuViDTsZqAwhalwWm",
  companyId: "7514a219-48ce-439e-a2c5-6f38980cee1a",
  stripePublishableKey:
    "pk_live_51LdnoABqYa4ICTHoC6CjEgImHYOvUXXPs8psg8jZqXPxRwBt99nQEWtFFJj2n3ublkCJa0S8pYzDtvStF2JxUPzU00uWCskvlb",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "AZ",

  //email confirmation admin token
  adminEmailToken:
    "XR85jFku2zMMqvsYmAdkrk1lm6pozj7eFXetRHLNSSsLxRLnAHgY3dPaVsE27OK8cIbnhFQmncWxVjS1XLAhneFEFpwVVeaNE7hMOym2vrhzdKSmxn99V0fLvVJyZLPT",
  websiteLink: "https://P23 Rentals-dev.1now.app",
  sender: "P23 Rentals",
  ccEmail: "P23 Rentals@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_2xca7kj",
  util_templateId: "template_smckeo7",
  util_userId: "ATdsFr5wumje11YkV",
  util_privateKey: "TJyyV_e7nNOGPKbyyjpMB",
  util_contact_templateId: "template_9dsuz0w",

  // apiUtil getBonzahToken
  client_secret_dev: "",
  client_id_dev: "",
  client_secret_prod: "",
  client_id_prod: "",

  about_us_para:
    "P 23 Rentals is a small commuter car rental company based out of Gaithersburg Maryland. We cater primarily to gig drivers who need both long-term and reliable transportation for work, but want to limit the wear and tear on their personal vehicles. Feel free to fill out the form and we will be in touch.",

    clientSays_arr: [
      {
        id: 1,
        clientName: "Frank L",
        clientAddress: "Dodge Grand Caravan 2019",
        content:
          "Car is well maintained mechanically and booking is straightforward.",
        img: clientSay1,
      },
      {
        id: 2,
        clientName: "Tim M.",
        clientAddress: "LondDodge Grand Caravan 2019",
        content:
          "Cars book fast but the website is intuitive. Great cars for gig work.",
        img: clientSay4,
      },
      {
        id: 3,
        clientName: "Liz R.",
        clientAddress: "Dodge Grand Caravan 2019",
        content:
          "Great car for Uber Eats and DoorDash deliveries, car has phone holder which is nice.",
        img: clientSay5,
      },
      {
        id: 4,
        clientName: "Rob A.",
        clientAddress: "Dodge Grand Caravan 2019",
        content:
          "Car has some dings and scrapes but drives well and interior is maintained.",
        img: clientSay5,
      },
    ],

  //social links
  facebook:
    "https://www.facebook.com/",
  instagram:
    "https://www.instagram.com/",

  //contact
  address: "N/A",
  addressLink:
    "https://www.google.com/maps/place/4830+W+Kennedy+Blvd,+Tampa,+FL+33609,+USA/data=!4m2!3m1!1s0x88c2c2e4b61fbe87:0x7fa31f86943d0895?sa=X&ved=1t:242&ictx=111",
  phone: "240-812-2329", //pending
  email: "p23rentals@gmail.com", //pending
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46776911.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
