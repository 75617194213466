import React from "react";

const WhyChooseUs = () => {
  const features = [
    {
      id: 1,
      title: "Unlimited Mileage",
      description:
        "We know how much of a nuisance mileage limits can be. With us that is a worry of the past.",
    },
    {
      id: 2,
      title: "Flexible Payment Options",
      description: "We accept credit card, Zelle, Apple pay, and Venmo.",
    },
    {
      id: 3,
      title: "Maintenance Free",
      description:
        "We ensure our vehicles are maintained often to deliver reliable service to you.",
    },
    {
      id: 4,
      title: "Flexible Insurance Options",
      description:
        "Want to use our services but don’t have insurance of your own? No worries! We can provide insurance for the duration of your rental.",
    },
  ];

  return (
    <section className="py-16 bg-white">
      <div className="max-w-screen-xl mx-auto px-6">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
          Why Choose Us
        </h2>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature) => (
            <div key={feature.id} className="text-left space-y-4">
              {/* Circle with Number */}

              <div className="w-12 h-12  flex items-center justify-center rounded-full bg-[#121212BF] text-white text-lg font-bold">
                {feature.id}
              </div>

              {/* Title */}
              <h3 className="text-xl font-semibold text-gray-800">
                {feature.title}
              </h3>

              {/* Description */}
              <p className="text-gray-600 text-sm leading-relaxed">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;