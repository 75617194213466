import serverURL from 'api/auth/auth';
import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import toast from 'react-hot-toast';

interface ErrorResponse {
  error?: string;
  message?: string;
  detail?: string;
}

// Create an Axios instance
const customAxios: AxiosInstance = axios.create({
  baseURL: serverURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include Authorization token
customAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('access_token');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle response errors globally
customAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError<ErrorResponse>) => {
    if (error.response) {
      const status = error.response.status;
      const errorData = error.response.data;
      // if (status === 401) {
      //   const errorMessage =
      //     errorData?.detail || "Unauthorized: Please log in again.";
      //   toast.error(errorMessage);
      // } 
     
    } else {
      toast.error("Network error: Please check your connection.");
    }
    
    return Promise.reject(error);
  }
);

export default customAxios;
