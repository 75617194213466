import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { sendNewsLetterEmail } from "utils/apiUtil";
import toast from "react-hot-toast";
import { globalJson } from "global/global_json";
import { ContactFun } from "api/profile/profile";
import { Link } from "react-router-dom";
import RentalPolicies from "components/RentalPolicies/RentalPolicies";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: globalJson.address,
  },
  {
    title: "💌 EMAIL",
    desc: globalJson.email,
  },
  {
    title: "☎ PHONE",
    desc: globalJson.phone,
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [getEmail, setEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!getEmail.trim() || !emailRegex.test(getEmail)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
      return;
    }

    if (getEmail.length === 0 || newName.length === 0 || message.length === 0) {
      toast.error("Fields are required!");
      setLoading(false);
      return;
    }

    // Create the payload with name, email, and message
    const payload = {
      name: newName,
      email: getEmail,
      message: message,
    };

    const result = await ContactFun(payload);

    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Sent successfully!");
      setEmail("");
      setNewName("");
      setMessage("");
    }

    setLoading(false);
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact Us
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-6 lg:gap-12">
            <div className="mt-8">
              <h2 className="text-4xl font-bold">
                Let’s Discuss About Something
              </h2>
              <p className="my-4">
                we are committed to providing exceptional customer service and
                support. If you are experiencing technical difficulties or need
                assistance with one of our services,
              </p>
              <div className="p-6 bg-black rounded-2xl mt-2">
                <div className="py-2">
                  <span className="text-white font-semibold">Follow Us:</span>
                </div>
                <div className="flex space-x-2">
                  <Link
                    to="https://www.facebook.com"
                    className="cursor-pointer"
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="20" fill="white" />
                      <path
                        d="M23.2588 20.625H21.208V26.75H18.4736V20.625H16.2314V18.1094H18.4736V16.168C18.4736 13.9805 19.7861 12.75 21.7822 12.75C22.7393 12.75 23.751 12.9414 23.751 12.9414V15.1016H22.6299C21.5361 15.1016 21.208 15.7578 21.208 16.4688V18.1094H23.6416L23.2588 20.625Z"
                        fill="#121212"
                      />
                    </svg>
                  </Link>
                  <Link
                    to="https://www.instagram.com"
                    className="cursor-pointer"
                  >
                    <svg
                      width="41"
                      height="40"
                      viewBox="0 0 41 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.330078"
                        width="40"
                        height="40"
                        rx="20"
                        fill="white"
                      />
                      <path
                        d="M20.3252 16.6055C22.0479 16.6055 23.4697 18.0273 23.4697 19.75C23.4697 21.5 22.0479 22.8945 20.3252 22.8945C18.5752 22.8945 17.1807 21.5 17.1807 19.75C17.1807 18.0273 18.5752 16.6055 20.3252 16.6055ZM20.3252 21.8008C21.4463 21.8008 22.3486 20.8984 22.3486 19.75C22.3486 18.6289 21.4463 17.7266 20.3252 17.7266C19.1768 17.7266 18.2744 18.6289 18.2744 19.75C18.2744 20.8984 19.2041 21.8008 20.3252 21.8008ZM24.3174 16.4961C24.3174 16.9062 23.9893 17.2344 23.5791 17.2344C23.1689 17.2344 22.8408 16.9062 22.8408 16.4961C22.8408 16.0859 23.1689 15.7578 23.5791 15.7578C23.9893 15.7578 24.3174 16.0859 24.3174 16.4961ZM26.3955 17.2344C26.4502 18.2461 26.4502 21.2812 26.3955 22.293C26.3408 23.2773 26.1221 24.125 25.4111 24.8633C24.7002 25.5742 23.8252 25.793 22.8408 25.8477C21.8291 25.9023 18.7939 25.9023 17.7822 25.8477C16.7979 25.793 15.9502 25.5742 15.2119 24.8633C14.501 24.125 14.2822 23.2773 14.2275 22.293C14.1729 21.2812 14.1729 18.2461 14.2275 17.2344C14.2822 16.25 14.501 15.375 15.2119 14.6641C15.9502 13.9531 16.7979 13.7344 17.7822 13.6797C18.7939 13.625 21.8291 13.625 22.8408 13.6797C23.8252 13.7344 24.7002 13.9531 25.4111 14.6641C26.1221 15.375 26.3408 16.25 26.3955 17.2344ZM25.083 23.3594C25.4111 22.5664 25.3291 20.6523 25.3291 19.75C25.3291 18.875 25.4111 16.9609 25.083 16.1406C24.8643 15.6211 24.4541 15.1836 23.9346 14.9922C23.1143 14.6641 21.2002 14.7461 20.3252 14.7461C19.4229 14.7461 17.5088 14.6641 16.7158 14.9922C16.1689 15.2109 15.7588 15.6211 15.54 16.1406C15.2119 16.9609 15.2939 18.875 15.2939 19.75C15.2939 20.6523 15.2119 22.5664 15.54 23.3594C15.7588 23.9062 16.1689 24.3164 16.7158 24.5352C17.5088 24.8633 19.4229 24.7812 20.3252 24.7812C21.2002 24.7812 23.1143 24.8633 23.9346 24.5352C24.4541 24.3164 24.8916 23.9062 25.083 23.3594Z"
                        fill="#121212"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="border border-[#121212] p-6 lg:p-10 rounded-xl">
              <div className="py-4">
                <p className="text-xl font-semibold">Fill The Contact Form</p>
                <p className="text-sm">
                  Feel free to contact with us, we don’t spam your email
                </p>
              </div>
              <form className="grid grid-cols-1 gap-6" onSubmit={sendEmail}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={getEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit" className="min-w-[200px]">
                    {loading ? (
                      <div className="flex justify-center items-center col-span-full">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                      </div>
                    ) : (
                      "Send Message"
                    )}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className=" border-t border-black"></div>
      <div className="container relative py-16">
        <RentalPolicies />
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
