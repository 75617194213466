import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";

import { useLocation } from "react-router-dom";
import CarsListingModel from "models/CarsListingModel";
export interface FleetCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
  simpleImg?: boolean;
  onClick?: any;
}

const FleetCard: FC<FleetCardProps> = ({
  size = "default",
  className = "",
  data,
  simpleImg = false,
  onClick,
}) => {
  const {
    id,
    fleet_photos,
    name,
    color,
    seats,
    avg_car_price_per_day,
    thumbnail_photo_url,
  } = data!;
  const location = useLocation();

  console.log("Thumbnail URL:", thumbnail_photo_url);

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        {/* {simpleImg ? ( */}
        <img
          src={
            fleet_photos && fleet_photos.length > 0
              ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${encodeURIComponent(
                  thumbnail_photo_url
                )}`
              : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
          }
          alt="name"
          className="h-[200px] object-cover w-full"
        />

        {/* ) : (
          <GallerySlider
            uniqueID={`StayCard_${l_id}`}
            ratioClass="aspect-w-4 aspect-h-3 "
            galleryImgs={
              listing_images.length > 0
                ? listing_images.map((image) => image.path)
                : ["https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"]
            }
          />
        )} */}
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>

          <span className=" text-sm">
            <span className="font-semibold">{seats}</span> seats{" "}
            <span className="font-semibold">{color}</span> color
          </span>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {avg_car_price_per_day ? "$" + avg_car_price_per_day : "$" + 0}

            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /day
              </span>
            )}
          </span>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}${
        location.pathname === "/available-car" ? "cursor-pointer" : ""
      }`}
      data-nc-id="StayCard"
      onClick={onClick}
    >
      {renderSliderGallery()}
      {/* <Link to={"/"}> */}
      {renderContent()}
      {/* </Link> */}
    </div>
  );
};

export default FleetCard;
