"use client";

import { Fragment, useEffect, useState } from "react";
import { FC } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { ClockIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useData } from "data/data-provider";
import moment from "moment";

export interface RentalCarTimesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  dropOffLocationType?: boolean;
}

const timeSlots = Array.from({ length: 12 }, (_, i) => {
  const hour = i === 0 ? "12" : String(i).padStart(1, "0");
  return [`${hour}:00`, `${hour}:30`];
}).flat();

const periodOptions = ["AM", "PM"];

const RentalCarTimesRangeInput: FC<RentalCarTimesRangeInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  hasButtonSubmit = true,
  dropOffLocationType,
}) => {
  const {
    startDate,
    startHours,
    startMinutes,
    startPeriod,
    endDate,
    endHours,
    ourCompany,
    endMinutes,
    loadingAvailableFleet,
    endPeriod,
    setEndDate,
    setEndHours,
    setEndMinutes,
    setEndPeriod,
    setStartDate,
    setStartHours,
    setStartMinutes,
    setStartPeriod,
    pickupLocation,
    dropOffLocation,
    callAvailableFleetAPI,
  } = useData();

  const [startTime, setStartTime] = useState(`${startHours}:${startMinutes}`); // Default start time
  const [endTime, setEndTime] = useState(`5:00`); // Default end time

  const navigate = useNavigate();

  useEffect(() => {
    validateEndTime();
  }, [startDate]);

  // const validateStartDateTime = () => {
  //   if (startDate < new Date()) {
  //     toast.error(
  //       "Trip start date and time cannot be in the past. Please choose a date and time after the current time."
  //     );
  //   }
  //   return startDate < new Date();
  // };

  const validateEndTime = () => {
    if (
      startDate &&
      endDate &&
      startDate.toDateString() === endDate.toDateString()
    ) {
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);

      const startTimeObj = new Date();
      startTimeObj.setHours(
        startHour + (startPeriod === "PM" && startHour !== 12 ? 12 : 0),
        startMinute
      );

      const endTimeObj = new Date();
      endTimeObj.setHours(
        endHour + (endPeriod === "PM" && endHour !== 12 ? 12 : 0),
        endMinute
      );

      // if (endTimeObj < startTimeObj) {
      //   toast.error("Trip end date time cannot be before trip start date time");
      //   setEndTime(startTime);
      //   setEndPeriod(startPeriod);
      // }
    }
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <ClockIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block text-lg font-semibold text-white">
            {`${startTime} ${startPeriod}`} - {`${endTime} ${endPeriod}`}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Pick up - Drop off"}
          </span>
        </div>
      </>
    );
  };

  const isWithinOperationalHours = (
    startHours: string,
    startMinutes: string,
    startPeriod: string,
    endHours: string,
    endMinutes: string,
    endPeriod: string,
    ourCompany: any
  ): boolean => {
    if (ourCompany?.always_operational) {
      return true;
    }

    const convertTo24HourFormat = (hour: number, period: string) => {
      return hour + (period === "PM" && hour !== 12 ? 12 : 0);
    };

    // Company Operational Times
    const [opStartHour, opStartMinute] = ourCompany?.operational_start_time
      .split(":")
      .map(Number);
    const [opEndHour, opEndMinute] = ourCompany?.operational_end_time
      .split(":")
      .map(Number);

    const operationalStart = new Date();
    operationalStart.setHours(opStartHour, opStartMinute, 0, 0);

    const operationalEnd = new Date();
    operationalEnd.setHours(opEndHour, opEndMinute, 0, 0);

    // User Selected Start Time
    const startHour24 = convertTo24HourFormat(Number(startHours), startPeriod);
    const selectedStart = new Date();
    selectedStart.setHours(startHour24, Number(startMinutes), 0, 0);

    // User Selected End Time
    const endHour24 = convertTo24HourFormat(Number(endHours), endPeriod);
    const selectedEnd = new Date();
    selectedEnd.setHours(endHour24, Number(endMinutes), 0, 0);

    // Compare if selected times are within operational hours
    const isStartWithinHours =
      selectedStart >= operationalStart && selectedStart <= operationalEnd;
    const isEndWithinHours =
      selectedEnd >= operationalStart && selectedEnd <= operationalEnd;

    return isStartWithinHours && isEndWithinHours;
  };

  return (
    <>
      <Popover
        className={`RentalCarDatesRangeInput relative flex ${className}`}
      >
        {({ open }) => (
          <>
            <div
              className={`flex-1 z-10  flex items-center focus:outline-none ${
                open ? "nc-hero-field-focused rounded-lg" : ""
              }`}
            >
              <PopoverButton
                className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none `}
                onClickCapture={() => document.querySelector("html")?.click()}
              >
                {renderInput()}
              </PopoverButton>

              {/* BUTTON SUBMIT OF FORM */}
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-l">
                <div className="p-4 space-x-2 overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-[#FFFFFF] dark:bg-[#1F2937F2]">
                  <div className="grid grid-cols-2">
                    {/* Start Time Section */}
                    <div>
                      <h3 className="text-lg font-medium flex justify-center">
                        Start Time
                      </h3>
                      <div className="grid grid-cols-2">
                        {/* Start Time Column */}
                        <div className="flex flex-wrap overflow-y-auto h-48 hide-scrollbar">
                          {timeSlots.map((time) => (
                            <div
                              key={time}
                              className={`cursor-pointer py-2 px-4 hover:bg-primary-200 hover:text-white rounded-full ${
                                startTime === time
                                  ? "bg-primary-200 text-white"
                                  : ""
                              }`}
                              onClick={() => {
                                setStartTime(time);
                                const [hour, minutes] = time.split(":");
                                setStartHours(hour);
                                setStartMinutes(minutes);
                              }}
                            >
                              {time}
                            </div>
                          ))}
                        </div>

                        {/* Start AM/PM Column */}
                        <div className="flex flex-col max-w-fit overflow-y-auto h-48 hide-scrollbar">
                          {periodOptions.map((periodOption) => (
                            <div
                              key={periodOption}
                              className={`cursor-pointer py-2 px-4 hover:bg-primary-200 hover:text-white rounded-full ${
                                startPeriod === periodOption
                                  ? "bg-primary-200 text-white"
                                  : ""
                              }`}
                              onClick={() => setStartPeriod(periodOption)}
                            >
                              {periodOption}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* End Time Section */}
                    <div>
                      <h3 className="text-lg font-medium flex justify-center">
                        End Time
                      </h3>
                      <div className="grid grid-cols-2">
                        {/* End Time Column */}
                        <div className="flex flex-wrap overflow-y-auto h-48 hide-scrollbar">
                          {timeSlots.map((time) => (
                            <div
                              key={time}
                              className={`cursor-pointer py-2 px-4 hover:bg-primary-200 hover:text-white rounded-full ${
                                endTime === time
                                  ? "bg-primary-200 text-white"
                                  : ""
                              }`}
                              onClick={() => {
                                setEndTime(time);
                                const [hour, minutes] = time.split(":");
                                setEndHours(hour);
                                setEndMinutes(minutes);
                              }}
                            >
                              {time}
                            </div>
                          ))}
                        </div>

                        {/* End AM/PM Column */}
                        <div className="flex flex-col max-w-fit overflow-y-auto h-48 hide-scrollbar">
                          {periodOptions.map((periodOption) => (
                            <div
                              key={periodOption}
                              className={`cursor-pointer py-2 px-4 hover:bg-primary-200 hover:text-white rounded-full ${
                                endPeriod === periodOption
                                  ? "bg-primary-200 text-white"
                                  : ""
                              }`}
                              onClick={() => setEndPeriod(periodOption)}
                            >
                              {periodOption}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
      {hasButtonSubmit && (
        <div className="cursor-pointer  my-auto mr-4">
          {hasButtonSubmit && (
            <div className="flex justify-center">
              <div
                onClick={() => {
                  if (
                    !isWithinOperationalHours(
                      startHours,
                      startMinutes,
                      startPeriod,
                      endHours,
                      endMinutes,
                      endPeriod,
                      ourCompany
                    )
                  ) {
                    toast.error(
                      `Please select a pickup and drop-off time between our working hours of ${moment(
                        ourCompany?.operational_start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")} - ${moment(
                        ourCompany?.operational_end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}`
                    );
                    return;
                  }

                  if (pickupLocation === null) {
                    toast.error("Please enter Pick up location");
                  } else if (
                    dropOffLocation === null &&
                    dropOffLocationType === false
                  ) {
                    toast.error("Please enter Drop off location");
                  } else if (
                    pickupLocation !== null
                    // validateStartDateTime() === false
                  ) {
                    callAvailableFleetAPI(navigate);
                  }
                }}
                className={`h-14 md:h-16 w-full rounded-full lg:rounded-full bg-white hover:bg-white lg:p-6 flex items-center justify-center text-neutral-50 focus:outline-none ${
                  startDate && endDate ? "" : "opacity-50 pointer-events-none"
                }`}
              >
                {loadingAvailableFleet ? (
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  <>
                    <span className="flex lg:hidden text-lg text-black font-bold">
                      Search
                    </span>
                    <span className="hidden lg:flex">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_188_1103)">
                          <path
                            d="M19.5234 19.3607L13.9126 13.5257C15.3437 12.0904 16.2303 10.1118 16.2303 7.92962C16.2303 3.557 12.6733 0 8.30071 0C3.92809 0 0.371094 3.557 0.371094 7.92962C0.371094 12.3022 3.92809 15.8592 8.30071 15.8592C10.2201 15.8592 11.9816 15.1731 13.3545 14.0347L18.9789 19.8841C19.0529 19.9615 19.1518 20 19.2511 20C19.3455 20 19.4396 19.9649 19.5128 19.8946C19.6631 19.75 19.6676 19.511 19.5234 19.3607ZM8.30071 15.104C4.34459 15.104 1.1263 11.8857 1.1263 7.92962C1.1263 3.97349 4.34459 0.755201 8.30071 0.755201C12.2568 0.755201 15.4751 3.97349 15.4751 7.92962C15.4751 11.8857 12.2565 15.104 8.30071 15.104Z"
                            fill="#121212"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_188_1103">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RentalCarTimesRangeInput;
