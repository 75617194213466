import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import IMG from "images/travelhero.png";
import MobileSearch from "components/MobileSearchForm/MobileSearch";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div
      className={`relative bg-[#E8F3F6] overflow-visible min-h-[50vh] rounded-2xl mx-0 lg:mx-4 xl:mx-12 ${className}`}
      data-nc-id="HeroSection"
    >
      {/* Main Section */}
      <div className="relative z-10 flex flex-col lg:flex-row items-center mx-auto">
        {/* Left Column */}
        <div className="flex-1 space-y-6 text-center md:text-left px-8 py-4 lg:py-0 lg:px-12 xl:px-24">
          <h1 className="text-3xl lg:text-5xl xl:text-6xl font-bold text-[#121212] lg:mt-12 xl:mt-0">
            Economy Cars to Rent For Your Busy Schedule
          </h1>
          <p className="text-gray-600 leading-relaxed">
            Book one of our cars in minutes for your transportation needs around
            the DC, Maryland, Northern Virginia areas. Say goodbye to costly
            upkeep and wear and tear on your personal vehicle
          </p>
        </div>

        {/* Right Column */}
        <div className="flex-1 relative w-full">
          <img
            src={IMG} // Replace with actual car image
            alt="Car"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="lg:hidden absolute w-full px-4 top-80 bottom-10">
          <MobileSearch />
        </div>
      </div>

      {/* Hero Search Form */}
      <div className="hidden lg:block absolute lg:bottom-[-130px] xl:bottom-[-100px] left-0 xl:pl-4 z-40">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero3;
