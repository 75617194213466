import React, { FC, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";

import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { useLocation, useNavigate } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";
import { useData } from "data/data-provider";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { user, setIsAuthPanelOpen } = useData();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-2 relative flex justify-between items-center">
        <div className="flex justify-start items-center space-x-4 sm:space-x-10">
          <Logo />
        </div>
        <div className="hidden md:flex items-center ml-auto">
          <Navigation />
        </div>
        {location.pathname !== "/" && (
          <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
            <HeroSearchForm2MobileFactory />
          </div>
        )}

        <div className=" flex flex-shrink-0 items-center justify-end lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {/* <SwitchDarkMode /> */}
            {/* <SearchDropdown /> */}
            <div className="px-2" />
            {/* {user && (
              <AvatarDropdown />

              <div>

                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <PopoverButton
                        className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <img
                          src={
                            user?.photoURL ??
                            "https://img.freepik.com/premium-vector/avatar-profile-colorful-illustration-2_549209-82.jpg"
                          }
                          alt=""
                          className="w-8 h-8 rounded-lg overflow-hidden mr-2"
                        />
                        <div className="px-1" />

                        {user ? (
                          user.displayName
                        ) : (
                          <ButtonPrimary href="/signup">nc</ButtonPrimary>
                        )}
                      </PopoverButton>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <PopoverPanel className="absolute z-10 w-screen max-w-[150px] mt-1 -right-10 sm:right-0 px-4 bg-white dark:bg-neutral-800 top-full py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                          <span
                            onClick={() => {
                              logoutCall(navigate);
                            }}
                          >
                            Logout
                          </span>
                        </PopoverPanel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            )} */}
             {/* {!user && (
              <ButtonPrimary
                onClick={() => {
                  setIsAuthPanelOpen(true);
                }}
                className="bg-primary-200 rounded-lg hover:bg-primary-200"
              >
                SignIn/SignUp
              </ButtonPrimary>
            )} */}
          </div>
          <div className="flex lg:hidden items-center">
            {/* <SwitchDarkMode /> */}
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
