import React, { useState } from "react";
import Minus from "images/Minus.svg";
import Plus from "images/Plus.svg";
import NcImage from "shared/NcImage/NcImage";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="border my-4 border-[#121212] rounded-xl px-6">
      <button
        onClick={onToggle}
        className="flex justify-between w-full px-4 py-6 text-left items-center"
      >
        <span className="text-lg font-semibold leading-7 text-left text-[#121212]">
          {title}
        </span>
        <span>
          {isOpen ? (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="29.75"
                cy="29.5"
                r="29.5"
                transform="rotate(90 29.75 29.5)"
                fill="#121212"
              />
              <path
                d="M29.8929 34.9609C30.0268 34.9609 30.1775 34.894 30.2779 34.7935L38.0792 26.9922C38.1797 26.8917 38.2467 26.7411 38.2467 26.6071C38.2467 26.4732 38.1797 26.3225 38.0792 26.2221L37.2422 25.385C37.1417 25.2846 36.9911 25.2176 36.8571 25.2176C36.7232 25.2176 36.5725 25.2846 36.4721 25.385L29.8929 31.9643L23.3136 25.385C23.2132 25.2846 23.0625 25.2176 22.9286 25.2176C22.7779 25.2176 22.644 25.2846 22.5435 25.385L21.7065 26.2221C21.606 26.3225 21.5391 26.4732 21.5391 26.6071C21.5391 26.7411 21.606 26.8917 21.7065 26.9922L29.5078 34.7935C29.6083 34.894 29.7589 34.9609 29.8929 34.9609Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="30.25" cy="29.5" r="29.5" fill="#121212" />
              <path
                d="M35.7109 29.3571C35.7109 29.2232 35.644 29.0725 35.5435 28.9721L27.7422 21.1708C27.6417 21.0703 27.4911 21.0033 27.3571 21.0033C27.2232 21.0033 27.0725 21.0703 26.9721 21.1708L26.135 22.0078C26.0346 22.1083 25.9676 22.2589 25.9676 22.3929C25.9676 22.5268 26.0346 22.6775 26.135 22.7779L32.7143 29.3571L26.135 35.9364C26.0346 36.0368 25.9676 36.1875 25.9676 36.3214C25.9676 36.4721 26.0346 36.606 26.135 36.7065L26.9721 37.5435C27.0725 37.644 27.2232 37.7109 27.3571 37.7109C27.4911 37.7109 27.6417 37.644 27.7422 37.5435L35.5435 29.7422C35.644 29.6417 35.7109 29.4911 35.7109 29.3571Z"
                fill="white"
              />
            </svg>
          )}
        </span>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};

const RentalPolicies: React.FC = () => {
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };
  return (
    <>
      <div className="flex justify-center mb-16">
        <span className="text-3xl  font-semibold leading-10 text-center tracking-tight">
          Frequently Asked Questions FAQ
        </span>
      </div>
      <div className="max-w-3xl mx-auto mt-4">
        <Section
          title="What is the minimum age requirement for renting?"
          isOpen={openSectionIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            You must be 21 or older with a valid drivers license to rent.
          </span>
        </Section>
        <Section
          title="Can I leave the state of Maryland?"
          isOpen={openSectionIndex === 2}
          onToggle={() => handleToggle(2)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            Vehicles may only travel in the DMV area - Maryland, Washington DC,
            and Northern Virginia (Arlington, Fairfax, Loudoun, and Prince
            William Counties).
          </span>
        </Section>
        <Section
          title="Is there a security deposit needed for rentals?"
          isOpen={openSectionIndex === 3}
          onToggle={() => handleToggle(3)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            No, there is no security deposit!
          </span>
        </Section>
        <Section
          title=" Is there a minimum rental duration?"
          isOpen={openSectionIndex === 4}
          onToggle={() => handleToggle(4)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            Yes, our minimum rental period is 2 weeks.
          </span>
        </Section>
        <Section
          title="Do I need my own auto insurance to rent?"
          isOpen={openSectionIndex === 5}
          onToggle={() => handleToggle(5)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            If you do not have your own full coverage insurance policy, you can
            purchase our insurance to cover you for the duration of your rental.
          </span>
        </Section>
      </div>
    </>
  );
};

export default RentalPolicies;
