import React, { FC, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-bold text-[#3B3B3B] md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block !font-normal !text-md text-[#1B2430] dark:text-neutral-400">
            {subHeading}
          </span>
          <div className="flex items-center space-x-2">
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 15.5469C0 7.2626 6.71573 0.546875 15 0.546875C23.2843 0.546875 30 7.2626 30 15.5469C30 23.8311 23.2843 30.5469 15 30.5469C6.71573 30.5469 0 23.8311 0 15.5469Z"
                fill="#121212"
                fill-opacity="0.1"
              />
              <path
                d="M20.485 9.51923C20.6717 9.63634 20.8083 9.76942 20.895 9.91846C20.9806 10.0687 21.0117 10.2396 20.9883 10.4313C20.965 10.6217 20.9261 10.8003 20.8717 10.9671C20.8161 11.1339 20.7183 11.3255 20.5783 11.542C20.4372 11.7573 20.3161 11.9318 20.215 12.0654C20.1139 12.1979 19.985 12.3641 19.8283 12.564C19.6717 12.764 19.5778 12.8888 19.5467 12.9384C19.5467 12.9384 18.625 14.1361 16.7817 16.5315C16.6406 16.7314 16.3278 17.1638 15.8433 17.8286C15.3589 18.4946 14.9761 18.9938 14.695 19.3261C14.4294 19.6254 14.2383 19.8419 14.1217 19.9756C14.0039 20.1081 13.8044 20.2701 13.5233 20.4617C13.2422 20.6534 12.9922 20.7409 12.7733 20.7243C12.2889 20.7066 11.7811 20.3985 11.25 19.7999C9.78111 18.1699 9.03111 16.7646 9 15.584C9 15.2173 9.09 14.9801 9.27 14.8725C9.44889 14.7637 9.65556 14.7554 9.89 14.8476C10.1244 14.9387 10.3589 15.076 10.5933 15.2593C10.8278 15.4592 11.07 15.684 11.32 15.9336C11.57 16.182 11.8394 16.473 12.1283 16.8065C12.4183 17.1389 12.6489 17.3885 12.82 17.5553C13.0389 17.1058 13.4922 16.515 14.18 15.7827C14.5233 15.4172 14.9922 14.8849 15.5867 14.1858C16.18 13.4879 16.555 13.0555 16.7117 12.8887C16.8828 12.6888 17.2261 12.2648 17.7417 11.6165C18.2572 10.9671 18.6717 10.4845 18.985 10.1686C19.6094 9.53697 20.1094 9.3205 20.485 9.51923ZM9.91333 15.4829C9.77333 15.4001 9.67222 15.3835 9.61 15.4332C9.67222 15.4663 9.75 15.4912 9.84333 15.5077C9.85889 15.4912 9.88222 15.4829 9.91333 15.4829Z"
                fill="#040404"
              />
            </svg>
            <span className="font-medium">Best Cars around the world</span>
          </div>
          <div className="flex items-center space-x-2">
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 15.5469C0 7.2626 6.71573 0.546875 15 0.546875C23.2843 0.546875 30 7.2626 30 15.5469C30 23.8311 23.2843 30.5469 15 30.5469C6.71573 30.5469 0 23.8311 0 15.5469Z"
                fill="#121212"
                fill-opacity="0.1"
              />
              <path
                d="M20.485 9.51923C20.6717 9.63634 20.8083 9.76942 20.895 9.91846C20.9806 10.0687 21.0117 10.2396 20.9883 10.4313C20.965 10.6217 20.9261 10.8003 20.8717 10.9671C20.8161 11.1339 20.7183 11.3255 20.5783 11.542C20.4372 11.7573 20.3161 11.9318 20.215 12.0654C20.1139 12.1979 19.985 12.3641 19.8283 12.564C19.6717 12.764 19.5778 12.8888 19.5467 12.9384C19.5467 12.9384 18.625 14.1361 16.7817 16.5315C16.6406 16.7314 16.3278 17.1638 15.8433 17.8286C15.3589 18.4946 14.9761 18.9938 14.695 19.3261C14.4294 19.6254 14.2383 19.8419 14.1217 19.9756C14.0039 20.1081 13.8044 20.2701 13.5233 20.4617C13.2422 20.6534 12.9922 20.7409 12.7733 20.7243C12.2889 20.7066 11.7811 20.3985 11.25 19.7999C9.78111 18.1699 9.03111 16.7646 9 15.584C9 15.2173 9.09 14.9801 9.27 14.8725C9.44889 14.7637 9.65556 14.7554 9.89 14.8476C10.1244 14.9387 10.3589 15.076 10.5933 15.2593C10.8278 15.4592 11.07 15.684 11.32 15.9336C11.57 16.182 11.8394 16.473 12.1283 16.8065C12.4183 17.1389 12.6489 17.3885 12.82 17.5553C13.0389 17.1058 13.4922 16.515 14.18 15.7827C14.5233 15.4172 14.9922 14.8849 15.5867 14.1858C16.18 13.4879 16.555 13.0555 16.7117 12.8887C16.8828 12.6888 17.2261 12.2648 17.7417 11.6165C18.2572 10.9671 18.6717 10.4845 18.985 10.1686C19.6094 9.53697 20.1094 9.3205 20.485 9.51923ZM9.91333 15.4829C9.77333 15.4001 9.67222 15.3835 9.61 15.4332C9.67222 15.4663 9.75 15.4912 9.84333 15.5077C9.85889 15.4912 9.88222 15.4829 9.91333 15.4829Z"
                fill="#040404"
              />
            </svg>
            <span className="font-medium">Best Rates for All</span>
          </div>
          {location.pathname !== "/about" && (
            <ButtonPrimary onClick={()=>navigate("/about")}>View More About Us</ButtonPrimary>
          )}

          {!!btnText && (
            <ButtonPrimary className="rounded-md" href="/signup">
              {btnText}
            </ButtonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
