import rightImg from "images/image.png";
import rightImg2 from "images/image2.png";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import { useEffect } from "react";
import SectionHero2 from "containers/PageAbout/SectionHero2";
import WhyLevel305 from "components/why305/why305";
import ReadytoDrive from "./ReadytoDrive";
import readytodrive from "images/readytodrive.png";
import WhyChooseUs from "components/WhyChooseUs/WhyChooseUs";
import RentalPolicies from "components/RentalPolicies/RentalPolicies";

function PageHome3() {
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }, []);
  return (
    <>
      <div className="nc-PageHome3 relative overflow-hidden">
        {/* GLASSMOPHIN */}
        <BgGlassmorphism />
        {/* <DownloadLink /> */}

        {/* SECTION HERO */}
        {/* <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 "> */}
        <SectionHero3 className="mt-6" />

        <div className="container py-16 space-y-16 mt-40 lg:mt-36">
          <SectionHero
            rightImg={rightImg}
            heading="We Help People Get Economy Cars Fast and Conveniently"
            btnText=""
            subHeading={globalJson?.about_us_para}
          />
        </div>
        {/* </div> */}
        <div className="container relative pb-16">
          <SectionGridFeaturePlaces />
        </div>
        <div className="container relative py-16">
          <WhyChooseUs />
        </div>

        <div className="relative py-16">
          {/* <BackgroundSection /> */}
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
        <div className="container relative py-16">
          <RentalPolicies />
        </div>
      </div>
    </>
  );
}

export default PageHome3;
